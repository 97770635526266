import React, { useState, useRef } from 'react';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';

import CreateCategories from './create_categories';
import CategoriesQueryDrawerForm from './categories_query_form';
import CategoriesList from './categories_list';
import CategoriesQueryParams from './categories_query_params_display';
// Categories 入口组件

const CategoriesComponent = () => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [createCategoriesVisible, setCreateCategoriesVisible] = useState(false);

  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  return (
    <div>
      <Row>
        <Col span={14}>
          <Space>
            <h4>Categories 商品分类(热搜词)</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreateCategoriesVisible(true)}>新建</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><CategoriesQueryParams paramsObj={reqParams.current}></CategoriesQueryParams></Col>
      </Row>
      <CategoriesQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></CategoriesQueryDrawerForm>

      <CategoriesList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
      ></CategoriesList>

      <CreateCategories
        visible={createCategoriesVisible}
        setVisible={setCreateCategoriesVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreateCategories>
    </div>
  )
};

export default CategoriesComponent;
