import React, { useState, useEffect } from 'react';
// import { DatePicker } from 'antd';
import { CustomSelect } from '../../base_components/select_component';
import ListsItem from '../../base_components/list_item';
import { channelsRequestGet } from './custom_select_requests'

// Categories 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
  "id", "channel", "category_name", "icon", "img", "keywords", "p_height",
  "handle_staff_id", "add_time", "update_time", "explains"
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [
  "id", "icon", "img", "handle_staff_id", "add_time", "update_time"
];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [
  "icon", "img"
];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "id": "ID", "channel": "频道ID", "category_name": "分类名", "icon": "图标", "img": "背景图", "keywords": "关键词", "p_height": "背景图高",
  "handle_staff_id": "操作员ID", "add_time": "创建时间", "update_time": "更新时间", "explains": "说明"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
const SelectChannels = ({ value = '', onChange = e => console.log(e) }) => {
  const [channelOptions, setChannelOptions] = useState([]);
  useEffect(() => {
    channelsRequestGet()
      .then(respObj => {
        const channelsList = respObj.results;
        let updateOptions = [];
        for (let index = 0; index < channelsList.length; index++) {
          const element = channelsList[index];
          updateOptions.push({
            value: element.id,
            label: element.name
          })
        }
        setChannelOptions(updateOptions);
      })
      .catch(error => {
        console.log(error);
        setChannelOptions([]);
      });
  }, []);
  return (<CustomSelect options={channelOptions} value={value} onChange={onChange}></CustomSelect>)
};
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
// const SelectBoolean = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const formKeySelectComponentsObj = {
  // 'end_time': SelectEndTime,
  // exampleColumn: SelectBoolean,
  'channel': <SelectChannels />,
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = ["icon", "img"];

//  ~~~~~~ 表单get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = ["category_name"];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
  "id": 0, "channel": 1, "category_name": "", "icon": "", "img": "", "keywords": "", "p_height": 155,
  "handle_staff_id": 0, "add_time": "", "update_time": "", "explains": ""
}

// ~~~~~~ 图片列名称, 根据实际修改 ~~~~~~
const imageKeyNames = ["icon", "img"];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 1;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['category-admin/v1/category', ];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      url={item.icon_url}
      productTitle={item.category_name}
      productEffects={`分类说明: ${item.explains}`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
    ></ListsItem>
  </div>);
};

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent
};
