import React, { useState, useEffect, useRef } from 'react';

import ProductsList from '../../base_components/products_list';
import { CategoriesRequestGet } from './categories_requests';
// import CreateCategories from './create_categories';
import UpdateCategories from './update_categories';
import { ItemRendererComponent } from './categories_columns_label_config';

const judgeHasMore = resp => {
  if (Object.keys(resp).includes('next')
    && typeof resp.next === 'string'
    && resp.next.length > 5) {
    return true;
  }
  return false;
};

// 列表编辑 页面

const CategoriesList = ({ reloadListCount, setReloadListCount, requestParams }) => {
  const hasMore = useRef(true);
  const [hasMorestate, setHasMoreState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const reqParams = useRef({ page: 0 });

  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesRequestGetCount, setCategoriesRequestGetCount] = useState(0);

  // const [createCategoriesVisible, setCreateCategoriesVisible] = useState(false);
  const [updateCategoriesVisible, setUpdateCategoriesVisible] = useState(false);
  const [selectedObj, setSelectedObj] = useState({});

  useEffect(() => {
    // 加载、更新或创建数据后 重新读取列表
    reqParams.current = { ...requestParams };
    reloadListsHandle();
  }, [reloadListCount]);

  useEffect(() => {
    // useState才可以读取更多
    setHasMoreState(hasMore.current);
  }, [hasMore.current])

  useEffect(() => {
    // 读取更多 useEffect解决异步
    if (requesting) {
      setRequesting(false);
      loadMore();
    }
  }, [requesting])

  const loadMore = () => {
    // 读取更多列表
    if (hasMore.current && !loading) {
      const current_page = Number.isInteger(reqParams.current.page) ? reqParams.current.page + 1 : 1;
      reqParams.current = { ...reqParams.current, page: current_page };
      console.log(reqParams.current);
      setCategoriesRequestGetCount(categoriesRequestGetCount + 1);
      setLoading(true);
    } else {
      console.log('Error state: hasMore is ', hasMore.current, ', loading is ', loading);
    }
  };

  const reloadListsHandle = () => {
    // 重新读取数据
    reqParams.current = { ...reqParams.current, page: 0 };
    // console.log(reqParams.current);
    setCategoriesData([]);
    // setHasMore(true);
    hasMore.current = true;
    setHasMoreState(true);
    setLoading(false);
    setRequesting(true);  // 读取列表 使用 useEffect 解决异步问题
  };

  const categoriesGetResponse = resp => {
    console.log('resp process...');
    setLoading(false);
    if (Object.keys(resp).includes('code')
      && resp.code === 1000
      && Object.keys(resp).includes('results')) {
      setCategoriesData(categoriesData.concat(resp.results));
      // setHasMore(judgeHasMore(resp));   // 判断是否还有更多
      hasMore.current = judgeHasMore(resp);
      setHasMoreState(judgeHasMore(resp));
    } else {
      console.log('Error: get data...');
    }
  };

  const itemDetailClick = (index) => {
    // 打开详情编辑窗口
    // console.log(categoriesData[index]);
    let selObj = { ...categoriesData[index] };
    console.log(selObj);
    // 日期转 moment 时间
    // selObj.end_time = moment(dateFormat(selObj.end_time), 'YYYY-MM-DD HH:mm:ss');
    setSelectedObj(selObj);
    setUpdateCategoriesVisible(true);
  };

  const CurrentItemRendererComponent = ({ item, index }) => {
    // 每一行渲染 的组件
    return (
      <ItemRendererComponent item={item} index={index} itemDetailClick={itemDetailClick} />
    )
  }

  return (
    <div>
      <CategoriesRequestGet requestCount={categoriesRequestGetCount}
        reqParams={reqParams.current}
        responseDataHandle={categoriesGetResponse}></CategoriesRequestGet>
      <ProductsList
        productsData={categoriesData}
        loadMore={loadMore}
        hasMore={hasMorestate}
        ItemRendererComponent={CurrentItemRendererComponent}></ProductsList>

      <UpdateCategories
        visible={updateCategoriesVisible}
        setVisible={setUpdateCategoriesVisible}
        oldObj={selectedObj}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></UpdateCategories>
    </div>
  );
}

CategoriesList.defaultProps = {
  reloadListCount: 1,
  setReloadListCount: (addedNum) => { console.log(addedNum); },
  requestParams: { page: 0 }
}

export default CategoriesList;
