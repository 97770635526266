import React from "react";

import SiteLayout from '../components/site_layout';
import SEO from "../components/seo";

import CategoriesComponent from '../components/wx_store/categories';

const CategoryManagePage = () => (
  <SiteLayout>
    <SEO title="WX Category" />
    <CategoriesComponent></CategoriesComponent>
  </SiteLayout>
)

export default CategoryManagePage;
